// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-destinos-js": () => import("./../../../src/pages/destinos.js" /* webpackChunkName: "component---src-pages-destinos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intercambio-js": () => import("./../../../src/pages/intercambio.js" /* webpackChunkName: "component---src-pages-intercambio-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-orcamento-js": () => import("./../../../src/pages/orcamento.js" /* webpackChunkName: "component---src-pages-orcamento-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-country-js": () => import("./../../../src/templates/pageCountry.js" /* webpackChunkName: "component---src-templates-page-country-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

